import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { CardActionArea, CardMedia, Stack } from "@mui/material";
import {
  Blocker,
  BlockerFunction,
  useBlocker,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppColours } from "../../helpers/colors";
import { routesForBlockerPopUp } from "../../helpers/miscellaneous";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useCallback } from "react";

export type AppBarComponentProps = {
  hideNavLinks?: boolean;
};

function ShouldNavigateComponent({ blocker }: { blocker: Blocker }) {
  if (blocker.state === "blocked") {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: AppColours.LightPurple,
          alignSelf: "center",
          mt: { xs: 2, sm: 4 },
          pt: { xs: 2, sm: 2 },
          pb: { xs: 2, sm: 2 },
          borderRadius: 4,
        }}
      >
        <>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            sx={{
              width: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color={AppColours.MainBlack}
              variant="body2"
              gutterBottom
            >
              Leaving might make you lose your data. Proceed anyways?
            </Typography>

            <Box
              sx={{
                width: "inherit",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<CheckIcon sx={{ fontWeight: "bolder" }} />}
                onClick={() => blocker.proceed?.()}
                sx={{
                  width: "48%",
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={() => blocker.reset?.()}
                sx={{
                  width: "48%",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </>
      </Container>
    );
  }

  return null;
}

function AppBarComponent(props: AppBarComponentProps) {
  const { hideNavLinks } = props;

  const location = useLocation();
  const navigate = useNavigate();

  // route controller function
  const handleButtonRoute = (): void => {
    navigate("/join-for-free");
  };

  let shouldBlock = useCallback<BlockerFunction>(
    ({ historyAction, currentLocation, nextLocation }) => {
      let shouldBlockNavigation = false;
      switch (historyAction) {
        case "POP":
        case "PUSH":
          shouldBlockNavigation =
            routesForBlockerPopUp.includes(currentLocation?.pathname) &&
            ![
              "/join-for-life",
              "/join-coming-soon",
              "/payment-success",
              "/our-gift",
            ].includes(nextLocation?.pathname);

          break;
      }

      return shouldBlockNavigation;
    },
    [],
  );
  let blocker = useBlocker(shouldBlock);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position={location.pathname === "/" ? "fixed" : "static"}
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            {/* Desktop Nav content */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <Toolbar>
                <CardActionArea
                  style={{
                    width: 30,
                    borderRadius: 30,
                  }}
                  onClick={() => {
                    if (blocker && blocker.state !== "blocked") {
                      navigate("/");
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    loading="lazy"
                    image={require("../../assets/logodarkfull.jpg")}
                    style={{
                      height: 30,
                      width: 30,
                      borderRadius: 30,
                    }}
                  />
                </CardActionArea>

                <Typography
                  variant="body1"
                  noWrap
                  style={{ fontWeight: "bolder", marginLeft: "16px" }}
                  color="primary"
                >
                  Shareduled
                </Typography>
              </Toolbar>

              {!hideNavLinks && (
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexGrow: 0.4,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    onClick={() => scrollToSection("/")}
                    sx={{ py: "6px", px: "12px" }}
                    disableGutters
                  >
                    <Typography
                      variant="body2"
                      color={AppColours.MainBlack}
                      sx={{
                        transition: "all 0.3s ease-in-out",
                        // fontWeight: "bold",
                        letterSpacing: "1px",
                        "&:hover": {
                          backgroundColor: AppColours.LightPurple,
                          color: AppColours.mainPurple,
                          padding: "0.5rem 1rem",
                          borderRadius: "1.6rem",
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => scrollToSection("/")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography
                      variant="body2"
                      color={AppColours.MainBlack}
                      sx={{
                        transition: "all 0.3s ease-in-out",
                        // fontWeight: "bold",
                        letterSpacing: "1px",
                        "&:hover": {
                          backgroundColor: AppColours.LightPurple,
                          color: AppColours.mainPurple,
                          padding: "0.5rem 1rem",
                          borderRadius: "1.6rem",
                        },
                      }}
                    >
                      About Us
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      scrollToSection("features");
                    }}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography
                      variant="body2"
                      color={AppColours.MainBlack}
                      sx={{
                        transition: "all 0.3s ease-in-out",
                        // fontWeight: "bold",
                        letterSpacing: "1px",
                        "&:hover": {
                          backgroundColor: AppColours.LightPurple,
                          color: AppColours.mainPurple,
                          padding: "0.5rem 1rem",
                          borderRadius: "1.6rem",
                        },
                      }}
                    >
                      Features
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => scrollToSection("subscription")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography
                      variant="body2"
                      color={AppColours.MainBlack}
                      sx={{
                        transition: "all 0.3s ease-in-out",
                        letterSpacing: "1px",
                        "&:hover": {
                          backgroundColor: AppColours.LightPurple,
                          color: AppColours.mainPurple,
                          padding: "0.5rem 1rem",
                          borderRadius: "1.6rem",
                        },
                      }}
                    >
                      Subscription
                    </Typography>
                  </MenuItem>
                </Box>
              )}

              {!hideNavLinks && (
                <Button
                  variant="contained"
                  onClick={handleButtonRoute}
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    justifyItems: "center",
                    borderRadius: "1.6rem",
                    fontSize: "0.90rem",
                    padding: "0.65rem 3rem",
                    color: AppColours.MainYellow,
                  }}
                >
                  I WANT TO SIGN-UP FOR FREE
                </Button>
              )}

              {/* Take back your time Desktop button */}
              {/* {!hideNavLinks && (
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      scrollToSection("shareduled-power-section");
                    }}
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      backgroundColor: AppColours.MainRed,
                      p: 1,
                      pl: 2,
                      pr: 2,
                    }}
                  >
                    Take Back Your Time: Start Today
                  </Button>
                </Box>
              )} */}
            </Box>

            {/* Mobile Hamburger click */}
            {!hideNavLinks && (
              <Box sx={{ display: { sm: "", md: "none" } }}>
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={toggleDrawer(false)}
                >
                  <Box
                    sx={{
                      minWidth: "60dvw",
                      p: 2,
                      backgroundColor: "background.paper",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        flexGrow: 1,
                      }}
                    ></Box>

                    <MenuItem
                      onClick={() => scrollToSection("/")}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography
                        variant="body2"
                        color={AppColours.MainBlack}
                        sx={{
                          transition: "all 0.3s ease-in-out",
                          fontWeight: "bold",
                          letterSpacing: "1px",
                          "&:hover": {
                            backgroundColor: AppColours.LightPurple,
                            color: AppColours.mainPurple,
                            padding: "8px",
                          },
                        }}
                      >
                        Home
                      </Typography>
                    </MenuItem>

                    {/* About */}
                    <MenuItem
                      onClick={() => scrollToSection("/")}
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      About Us
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        scrollToSection("features");
                      }}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography
                        variant="body2"
                        color={AppColours.MainBlack}
                        sx={{
                          transition: "all 0.3s ease-in-out",
                          fontWeight: "bold",
                          letterSpacing: "1px",
                          "&:hover": {
                            backgroundColor: AppColours.LightPurple,
                            color: AppColours.mainPurple,
                            padding: "8px",
                          },
                        }}
                      >
                        Features
                      </Typography>
                    </MenuItem>

                    {/* Testimonial */}
                    {/* <MenuItem
                      onClick={() => scrollToSection("testimonial")}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography
                        variant="body2"
                        color={AppColours.MainBlack}
                        sx={{
                          transition: "all 0.3s ease-in-out",
                          fontWeight: "bold",
                          letterSpacing: "1px",
                          "&:hover": {
                            backgroundColor: AppColours.LightPurple,
                            color: AppColours.mainPurple,
                            padding: "8px",
                          },
                        }}
                      >
                        Testimonial
                      </Typography>
                    </MenuItem> */}

                    <MenuItem
                      onClick={() => scrollToSection("subscription")}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography
                        variant="body2"
                        color={AppColours.MainBlack}
                        sx={{
                          transition: "all 0.3s ease-in-out",
                          fontWeight: "bold",
                          letterSpacing: "1px",
                          "&:hover": {
                            backgroundColor: AppColours.LightPurple,
                            color: AppColours.mainPurple,
                            padding: "8px",
                          },
                        }}
                      >
                        Subscription
                      </Typography>
                    </MenuItem>

                    <Button
                      variant="contained"
                      onClick={handleButtonRoute}
                      sx={{
                        width: "16rem",
                        justifyItems: "center",
                        marginTop: "3rem",
                        borderRadius: "10px",
                        color: AppColours.MainYellow,
                      }}
                    >
                      I WANT TO SIGN-UP FOR FREE
                    </Button>
                    {/* <Divider /> */}

                    {/* <MenuItem>
                      <Button
                        variant="contained"
                        component="a"
                        onClick={() => {
                          scrollToSection("shareduled-power-section");
                        }}
                        sx={{
                          width: "100%",
                          borderRadius: "8px",
                          textTransform: "none",
                          backgroundColor: AppColours.MainRed,
                        }}
                      >
                        Take Back Your Time: Start Today
                      </Button>
                    </MenuItem> */}
                  </Box>
                </Drawer>
              </Box>
            )}
          </Toolbar>

          {blocker ? <ShouldNavigateComponent blocker={blocker} /> : null}
        </Container>
      </AppBar>
    </div>
  );
}

export default AppBarComponent;
