import { Box, Button, Container, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  // Use the navigate hook to navigate to the checkout page
  const navigate = useNavigate();
  const handleButtonRoute = (): void => {
    navigate("/join-for-free");
  };

  const listData = [
    {
      id: 1,
      text: "You can now attend to clients at your most convenient time… and have more time to handle other aspects of business.",
    },
    {
      id: 2,
      text: "You'll never worry about clients ghosting you after booking an appointment because of our “locked in” policy (you will LOVE this).",
    },
    {
      id: 3,
      text: "And you'll retain more clients and skyrocket your business in record time.",
    },
  ];

  return (
    <Container>
      <Box textAlign="center" marginTop={8} id="/">
        <Box component="div">
          <Typography
            variant="body2"
            component="p"
            sx={{
              color: "red",
              textAlign: { xs: "left", md: "center" },
              fontWeight: "bold",
              marginY: 3,
            }}
          >
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
              }}
            >
              Shareduled Business
            </Box>{" "}
            is available on both Google PlayStore and Apple App Store <br />
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
              }}
            >
              Shareduled
            </Box>{" "}
            now available on both Google PlayStore and Apple App Store
          </Typography>

          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "#757575",
              fontWeight: "bold",
              textAlign: { xs: "left", md: "center" },
            }}
          >
            If you want to join the ranks of top performing businesses with ZERO
            scheduling “headaches” then you should…
          </Typography>

          <Typography
            variant="h3"
            component="h3"
            fontWeight={700}
            marginTop={4}
            sx={{
              textAlign: { xs: "left", md: "center" },
            }}
          >
            Get{" "}
            <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
              “Shareduled”
            </Box>{" "}
            - The World's First App Ever Designed For Paid Appointment Booking
            In{" "}
            <Box
              component="span"
              sx={{
                // color: "#550066",
                // textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Real-Time
            </Box>
          </Typography>
        </Box>
      </Box>

      {/* Adjust Box for listData mapping */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          width: "100%",
          flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile, horizontally on larger screens
          marginTop: "2rem", // Ensure some space between sections
        }}
      >
        {listData.map((item, index) => (
          <Box
            key={index}
            component="div"
            display="flex"
            gap={2}
            alignItems="center"
            sx={{ width: "100%", marginBottom: "1rem" }} // Make sure each item has some spacing between them
          >
            <Typography
              sx={{
                backgroundColor: AppColours.LightPurple,
                fontWeight: "bold",
                fontSize: "16px",
                padding: "0.5rem 1rem",
                display: "flex",
                textAlign: "center",
                width: "4rem",
                borderRadius: "10px",
              }}
            >
              {item.id}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: AppColours.Nuetral,
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Button and Link Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleButtonRoute}
          sx={{
            width: "26rem",
            marginBottom: "1rem",
            justifyItems: "center",
            marginTop: "3rem",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          I WANT TO SIGN-UP FOR FREE
        </Button>
        <br />
      </Box>

      <Link
        to="/join-for-free"
        style={{
          fontWeight: "bold",
          fontSize: "10px",
          textAlign: "center",
          display: "block",
        }}
      >
        *3 DAYS FREE TRIAL- NO CREDIT CARD REQUIRED
      </Link>
    </Container>
  );
};

export default Hero;
